import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

// Components
import PageLayout from '../components/Layout/PageLayout'
import CustomForm from '../components/Forms/CustomForm'

const CustomLayouts = ({ location }) => {
  return (
    <PageLayout location={location} title="Custom" pageTitle="Custom Layouts">
      <p>Do you need specific sizing for your track plan?</p>
      <p>
        To order a custom layout made to fit your space perfectly, please use the form below to add
        all the relevant details. For further questions, please{' '}
        <Link className="text-link" to={'/contact'}>
          contact us
        </Link>
        .
      </p>
      <CustomForm />
    </PageLayout>
  )
}

CustomLayouts.propTypes = {
  location: PropTypes.object.isRequired,
}

export default CustomLayouts
