import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

// https://stackoverflow.com/questions/58065376/how-to-add-radio-button-in-formik-validations-reactjs

const RadioBool = ({ name, label }) => {
  const options = [
    { key: 'yes', value: 'yes' },
    { key: 'no', value: 'no' },
  ]

  return (
    <>
      {/* <p id={`${id}-group-label`}>{label}:</p>
      <div className="form-group mb-4" role="group" aria-labelledby={`${id}-group-label`}>
        <div className="form-check">
          <label htmlFor={yesId}>Yes</label>
          <Field id={yesId} className="form-check-input" type="radio" name={name} value={true} />
        </div>
        <div className="form-check">
          <label htmlFor={noId}>No</label>
          <Field id={noId} className="form-check-input" type="radio" name={name} value={false} />
        </div>
      </div> */}
      <div className="form-group mb-4" role="group" aria-labelledby={name}>
        <label htmlFor={name}>{label}:</label>
        <Field name={name} className="form-check-input">
          {({ field }) => {
            return options.map(({ key, value }) => {
              return (
                <div key={key}>
                  <input
                    type="radio"
                    id={key}
                    {...field}
                    value={value}
                    checked={field.value === value}
                  />
                  <label htmlFor={key} style={{ marginLeft: '0.5em' }}>
                    {key}
                  </label>
                </div>
              )
            })
          }}
        </Field>
      </div>
    </>
  )
}

RadioBool.propTypes = {
  // id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default RadioBool
