import React from 'react'
import { Formik, Form } from 'formik'
import toast, { Toaster } from 'react-hot-toast'

// Components
import TextForm from './TextForm'
import BtnOutline from '../Buttons/BtnOutline'
import DropDownForm from './DropDownForm'
import RadioBool from './RadioBool'

// Utils
import { formSubmitter } from '../../utils/form'

// JSON
import trackJson from '../../../data/track.json'

const formName = 'custom-form'
const sc = ['025', '031', '036', '048', '058', '072', '084', '096']

const CustomForm = () => {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container my-5">
        <div className="col-10 offset-1">
          <Formik
            initialValues={{
              width: '',
              height: '',
              trackBrand: '',
              mainLines: '',
              smallestCurve: '',
              sidings: '',
              switchCount: '',
              elevated: 'no',
              crossovers: 'no',
              name: '',
              email: '',
              subject: '',
              content: '',
            }}
            validate={({ width, height, name, email, subject, content }) => {
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              const errors = {}

              if (!width || width.trim().length === 0) errors.width = 'Width Required'
              if (!height || height.trim().length === 0) errors.height = 'Height Required'
              if (!name || name.trim().length === 0) errors.name = 'Name Required'
              if (!email || !emailRegex.test(email)) errors.email = 'Valid Email Required'
              if (!subject || subject.trim().length === 0) errors.subject = 'Subject Required'
              if (!content || subject.trim().content === 0) errors.content = 'Message Required'

              return errors
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              if (process.env.NODE_ENV === 'development') {
                return setTimeout(() => {
                  toast.success(JSON.stringify(values, null, 2))
                  console.log({ values })
                  setSubmitting(false)
                  resetForm()
                }, 1000)
              }
              return await formSubmitter(formName, values, setSubmitting, resetForm)
            }}
          >
            {({ isSubmitting }) => (
              <Form
                className="contact-form"
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name={formName}
              >
                <TextForm name="width" label="width" placeholder="What is the width in feet?" />
                <TextForm name="height" label="height" placeholder="What is the height in feet?" />
                <DropDownForm
                  id="trackBrand"
                  name="trackBrand"
                  label="Track Brand"
                  options={trackJson.map(track => track.name)}
                  placeholder="What brand of track to use"
                />
                <TextForm
                  name="mainLines"
                  label="main lines"
                  required={false}
                  placeholder="How many lines the layout should have"
                />
                <DropDownForm
                  id="smallestCurve"
                  name="smallestCurve"
                  label="smallest curve"
                  options={sc}
                  placeholder="Smallest Curve to use in the layout?"
                />
                <TextForm
                  name="sidings"
                  label="siding"
                  required={false}
                  placeholder="How many sidings should the layout have"
                />
                <TextForm
                  name="switchCount"
                  label="switch count"
                  required={true}
                  placeholder="How many switches would you like?"
                />
                <RadioBool id="elevated" name="elevated" label="Should the layout be elevated" />
                <RadioBool
                  id="crossovers"
                  name="crossovers"
                  label="Should the layout have crossovers"
                />
                <TextForm
                  id="name"
                  name="name"
                  label="full name"
                  placeholder="Please enter your name"
                />
                <TextForm
                  id="email"
                  name="email"
                  label="email address"
                  type="email"
                  placeholder="Please enter your email"
                />
                <TextForm
                  id="subject"
                  name="subject"
                  label="subject"
                  placeholder="Please enter your subject"
                />
                <TextForm
                  id="content"
                  name="content"
                  label="content"
                  placeholder="Please enter your message"
                  as="textarea"
                  rows="5"
                />
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value={formName} />
                <div className="form-group mb-5">
                  <BtnOutline type="submit" size={'lg'} disabled={isSubmitting}>
                    {isSubmitting ? 'Please wait...' : 'Submit'}
                  </BtnOutline>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

CustomForm.propTypes = {}

export default CustomForm
