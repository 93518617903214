import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

// Utils
import { toTitleCase } from '../../utils'

const DropDownForm = ({ name, label, id, placeholder, options }) => {
  return (
    <div className="form-group mb-4">
      <label htmlFor={name}>{toTitleCase(label)}:</label>
      <Field
        aria-label={`${name}-dropdown`}
        name={name}
        className="form-control"
        list={id}
        id={`${id}DropDown`}
        placeholder={placeholder}
      />
      <datalist id={id}>
        {options.map((option, key) => (
          <option
            key={key}
            value={option.toString()}
            aria-label={option.toString()}
            data-value={option.toString()}
          />
        ))}
      </datalist>
    </div>
  )
}

DropDownForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

export default DropDownForm
